import React from "react"
import { Link } from "gatsby"
import Scrollchor from "react-scrollchor"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CustomVideoPlayer from "../components/CustomVideoPlayer"

class ProjectPage extends React.PureComponent {

  render() {
    const { pageContent } = this.props.pageContext
    return (
      <Layout>
        <SEO title="Project Detail" />
        <div className="hero-section">
          <div className="hero-inner-section">
            <div className="container">
              <div className="row">
                <div className="display-block mb-4">
                  <Link className="back-button" to="/">
                    BACK
                  </Link>
                </div>
              </div>
            </div>
            <div className="container video-outter-container">
              <div className="row">
                <CustomVideoPlayer
                  videoSrc={pageContent.viemo_video_link}
                  isHeroVideo={true}
                  isViemoVideo={true}
                  noInfo={true}
                  showControls={true}
                  muted={true}
                  stopHoverPlayEffect={true}
                  containerCssClasses="col-md-12"
                  reactPlayerCustomCssClasses="custom-video-styles"
                  customStyles={{marginBottom: 0}}
                />
              </div>
            </div>
            <div className="container hero-content-container mt-4 pt-4 pb-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="heading">
                    <b>{pageContent.subtitle}</b>
                    {/* <br />
                    <span className="regular">{pageContent.title}</span> */}
                  </h1>
                  <p style={{marginTop: '0px'}} className="description">
                    {pageContent.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="container position-relative">
              <Scrollchor to="#content1" className="scroll-arrow sa-project"> </Scrollchor>
            </div>
          </div>
        </div>
        <div id="content1" className="project-content-container">
          <div className="container">
            
            <div className="row">
              <div className="col-md-12 quote-container">
                {pageContent.quote}
              </div>
            </div>
            
            {(pageContent.text_1_heading !== null || pageContent.text_1_paragraph !== null || pageContent.text_2_heading !== null || pageContent.text_2_paragraph !== null) &&
              <div className="row text-para-container">
                <div className="col-md-6">
                  <p className="heading">{pageContent.text_1_heading}</p>
                  <p className="para">{pageContent.text_1_paragraph}</p>
                </div>
                <div className="col-md-6">
                  <p className="heading">{pageContent.text_2_heading}</p>
                  <p className="para">{pageContent.text_2_paragraph}</p>
                </div>
              </div>
            } 
            <div className="detail-images-container row pt-4 pb-4">
              {pageContent.grid_image_1 !== null &&
                <div className="col-md-4">
                  <img src={pageContent.grid_image_1.localFile.childImageSharp.fluid.src} alt="img" />
                </div>
              }
              {pageContent.grid_image_2 !== null && 
                <div className="col-md-4">
                  <img src={pageContent.grid_image_2.localFile.childImageSharp.fluid.src} alt="img" />
                </div>
              }
              {pageContent.grid_image_3 !== null && 
                <div className="col-md-4">
                  <img src={pageContent.grid_image_3.localFile.childImageSharp.fluid.src} alt="img" />
                </div>
              }
            </div>
          </div>
          <div className="talk-to-outter-container">
            <div className="talk-to-container">
              <div className="container">
                <section>
                  <h2 className="h2">We're excited to hear from you</h2>
                  <p>
                  We'd love to get in touch. Feel free to ask us everything - we are thrilled to learn more about your story.
                  </p>
                  <a className="projects-link-1" href="mailto:team@framehub.io">
                    Drop us a mail
                  </a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ProjectPage
